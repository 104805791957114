.wrapper {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  margin-bottom: var(--section-spacing-default);
  margin-top: var(--spacing-20px);
}

.iconAndButtonVisibleStyles {
  height: 200px;
}

.iconAndButtonHiddenStyles {
  height: 270px;
}

.iconOnlyHiddenStyles {
  height: 270px;
}

.icon {
  position: relative;
  height: 71px;
  width: 100%;
}

.action {
  padding: 12px 14px;
  height: 36px;
  min-width: 121px;
  text-transform: initial;
  color: var(--color-background-primary);
  margin-top: 20px;
  background-color: var(
    --promobanner-btn-bg-color
  ); /* overriden to fix a11y contrast issues */
}

.contentContainer {
  h5 {
    line-height: 2rem;
    font-size: 24px;
    font-family: var(--font-family-primary);
    font-weight: var(--typography-body-xlarge-bold-font-weight);

    & > span:nth-child(2) {
      font-weight: normal;
    }
  }

  h6 {
    margin-top: 1.25rem;
    line-height: 1.5rem;
    font-size: 1.125rem;
    font-family: var(--font-family-primary);
    font-weight: unset;
  }

  .gradient {
    background: linear-gradient(
      180deg,
      #0c254c 27.22%,
      rgba(12, 37, 76, 0%) 110.74%
    );
  }

  p {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    font-family: var(--font-family-primary);
  }
}

.contentWhite {
  --color-typography-heading: #fff;
  --color-secondary-muted: #fff;

  color: white;
}

.description {
  width: 400px;
  margin-top: 16px;
}

.iconOnlyBtn {
  margin-top: 78px;
  height: 48px !important;
  padding: 14px 16px !important;
}

.iconSize {
  --btn-icon-size: 20px;

  margin-left: 10px;
}

@media screen and (--tablet-s) {
  .gradient {
    background: linear-gradient(
      90deg,
      #0c254c 16.87%,
      rgba(12, 37, 76, 0%) 50%
    );
  }
}

@media screen and (--desktop) {
  .wrapper {
    max-width: 764px;
    margin-left: auto;
    margin-right: auto;
  }

  .iconAndButtonVisibleStyles {
    height: 251px;
  }

  .iconAndButtonHiddenStyles {
    height: 144px;
  }

  .iconOnlyHiddenStyles {
    height: 204px;
  }

  .action {
    padding: 14px 16px;
    height: 44px;
    min-width: 137px;
    margin-top: 24px;
  }

  .iconOnlyBtn {
    margin-top: 20px !important;
  }
}

@media screen and (--desktop-m) {
  .wrapper {
    max-width: 1064px;
  }

  .iconAndButtonVisibleStyles {
    height: 274px;
  }

  .icon {
    height: 87px;
  }

  .action {
    margin-top: 31px;
  }
}

@media screen and (--desktop-l) {
  .wrapper {
    max-width: 1464px;
  }

  .iconAndButtonVisibleStyles {
    height: 340px;
  }

  .action {
    padding: 16px;
    height: 56px;
    min-width: 184px;
    margin-top: 61px;
  }
}
